
// fichier SearchFeuillesDuJour.js

import React, { useEffect, useState }                       from 'react' 
import { collection, getDocs, query, where, Timestamp }     from 'firebase/firestore'
import { db }                                               from '../../firebase.config'
import FeuillesMensuellesAdmin                              from './FeuillesMensuellesAdmin'
import download                                             from "../../assets/download.png"  
import exportToExcel                                        from '../ExportToExcel'
import { startOfMonth, endOfMonth }                         from "date-fns"
import SearchFeuillesHebdo                                  from './SearchFeuillesHebdo'


function SearchFeuillesDuJour() {
    const [feuillesDeRoute, setFeuillesDeRoute]             = useState([]);
    const [usersMap, setUsersMap]                           = useState({});
    const [selectedUser, setSelectedUser]                   = useState(null);
    const [startDate, setStartDate]                         = useState("");
    const [endDate, setEndDate]                             = useState("");
    const [searchTerm, setSearchTerm]                       = useState('');
    const [filteredUsers, setFilteredUsers]                 = useState([]);
    const [showResults, setShowResults]                     = useState(false); 
    const [nombreVisites, setNombreVisites]                 = useState(0);
    const [nombreVisitesClient, setNombreVisitesClient]     = useState(0);
    const [nombreVisitesProspect, setNombreVisitesProspect] = useState(0);
    const [formatt, setFormatt]                             = useState('')
    const [selectedMonthYear, setSelectedMonthYear]         = useState('')
    const [nombreFichesDemonstration, setNombreFichesDemonstration] = useState(0); 
    const [showDaily, setShowDaily]                         = useState(false)
    const [showMonthly, setShowMonthly]                     = useState(false);
    const [error, setError]                                 = useState("")

    // Réinitialise le formulaire
    const resetForm = () => {
        setSearchTerm("")
        setStartDate("")
        setEndDate("")
        setSelectedMonthYear("")
        setFormatt("")
        setShowResults(false)
    }

    // Récupère les noms des VRP
    useEffect(() => {
        const fetchUsersData = async () => {
            const usersData = {}

            try {
                const usersSnapshot = await getDocs(collection(db, 'users'))

                usersSnapshot.forEach((doc) => {
                    usersData[doc.id] = doc.data()
                })

                setUsersMap(usersData)
            } 
            catch (error) {
                console.error("Erreur lors de la récupération des utilisateurs : ", error)
            }
        }
    
        fetchUsersData()
    }, [])

    const getNombreDeVisites = (stops) => {
        return stops.length > 1 ? stops.length - 1 : 0
    }
    const countVisitesByStatus = (stops, status) => {
        return stops.filter(stop => stop.status === status).length 
    }
    const calculateVisites = (feuilles) => {
        let totalVisites = 0;
        let totalVisitesClient = 0;
        let totalVisitesProspect = 0;

        feuilles.forEach(feuille => {
            const stops = feuille.stops || [];
            totalVisites += stops.length > 1 ? stops.length - 1 : 0;

            totalVisitesClient += stops.filter(stop => stop.status === 'Client').length;
            totalVisitesProspect += stops.filter(stop => stop.status === 'Prospect').length;
        });

        setNombreVisites(totalVisites);
        setNombreVisitesClient(totalVisitesClient);
        setNombreVisitesProspect(totalVisitesProspect);
    }

    const formatDistance = (distance) => {
        if (distance < 1000) {
            return `${distance.toFixed(0)} m`;
        }
        return `${(distance / 1000).toFixed(2)} km`;
    }
    const formatTimestamp = (timestamp) => {
        if (!timestamp) {
            return ''; 
        }
        const dateObject = timestamp.toDate();
        return dateObject.toLocaleDateString(); 
    }
    const formatDate = (dateStr) => {
        if (!dateStr) return '';
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return new Date(dateStr).toLocaleDateString('fr-FR', options);
    }

    const handleUserSearch = (event) => {
        const { value } = event.target;
        setSearchTerm(value);

        const filtered = Object.values(usersMap).filter(user =>
            user.role === 'commercial' &&
            (user.firstname.toLowerCase().includes(value.toLowerCase()) || user.lastname.toLowerCase().includes(value.toLowerCase()))
        )
        setFilteredUsers(filtered);
    }
    const handleUserSelection = (user) => {
        setSelectedUser(user);
        setSearchTerm(`${user.firstname} ${user.lastname}`)
        setFilteredUsers([]);
    }

    const handleStartDateChange = (event) => {
        const { value } = event.target;
        setStartDate(value);
    }
    const handleEndDateChange = (event) => {
        const { value } = event.target;
        setEndDate(value);
    }
    const handleFormatChange = (event) => { 
        const selectedFormat = event.target.value

        setFormatt(selectedFormat)
        setShowDaily(selectedFormat === 'daily')
        setShowMonthly(selectedFormat === 'monthly')
    }
/*
    
    
    const handleMonthChangeForFirefox  = (event) => {
        const inputValue = event.target.value;

        // Toujours mettre à jour la valeur de l'input
        setSelectedMonthYear(inputValue);

        // Vérifier le format 'MM/YYYY' et afficher une erreur uniquement si la saisie est complète mais incorrecte
        const regex = /^(0[1-9]|1[0-2])\/\d{4}$/;

        if (inputValue.length === 7 && !regex.test(inputValue)) {
            setError("Veuillez entrer une date valide au format 'MM/AAAA'.");
        } else {
            setError(""); // Réinitialiser l'erreur si valide ou incomplet
        }
    }

    // Fonction de gestion pour les navigateurs autres que Firefox
    const handleMonthChangeDefault = (event) => {
        const newMonthYear = event.target.value
        setSelectedMonthYear(newMonthYear)
        setError("")
    }*/
        const isFirefox = () => navigator.userAgent.toLowerCase().includes("firefox")


        const handleMonthChange = (event) => {
            //const newMonthYear = event.target.value
            //setSelectedMonthYear(newMonthYear)
           

            const inputValue = event.target.value;

            // Vérification si le navigateur est Firefox
            if (isFirefox()) {
                const monthNames = [
                "janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"
                ];
        
                // Vérification si l'utilisateur a saisi un mois et une année sous forme de texte
                const regex = /^([a-zéûàç]{3,9})\s*(\d{4})$/i;
                const match = inputValue.match(regex);
        
                if (match) {
                const monthName = match[1].toLowerCase();
                const year = match[2];
                const monthIndex = monthNames.findIndex(name => name.startsWith(monthName));
        
                if (monthIndex !== -1) {
                    // Mise à jour de l'état avec la date formatée "YYYY-MM"
                    const formattedMonthYear = `${year}-${(monthIndex + 1).toString().padStart(2, '0')}`;
                    setSelectedMonthYear(formattedMonthYear);
                }
                } else {
                // Si le format n'est pas valide, ne pas changer la valeur
                setSelectedMonthYear(inputValue);
                }
            } else {
                // Pour les autres navigateurs (utilisation d'un input de type "month")
                setSelectedMonthYear(inputValue);
            }
        }
    
    const handleSubmit = () => {

        if (!selectedUser) {
            setFeuillesDeRoute([])
            setNombreVisites(0)
            setNombreVisitesClient(0)
            setNombreVisitesProspect(0)

            setError("Veuillez sélectionner un VRP.")
            return
        }
        
        if ((!startDate || !endDate) && !selectedMonthYear) {
            setFeuillesDeRoute([])
            setNombreVisites(0)
            setNombreVisitesClient(0)
            setNombreVisitesProspect(0)
            
            setError("Veuillez sélectionner une période (une date de début et une date de fin) OU sélectionnez un mois dans la liste déroulante.")
            return
        }

        if (formatt === "") {
            setFeuillesDeRoute([])
            setNombreVisites(0)
            setNombreVisitesClient(0)
            setNombreVisitesProspect(0)
            
            setError("Veuillez sélectionner le type de feuilles de route que vous souhaitez : jounalières OU mensuelles.")
            return
        }

        if (selectedUser && startDate && endDate && (formatt === "monthly")) {
            setFeuillesDeRoute([])
            setNombreVisites(0)
            setNombreVisitesClient(0)
            setNombreVisitesProspect(0)

            setError('Si vous souhaitez visionner le format de type "Feuilles mensuelles", veuillez sélectionner un mois dans la liste déroulante (date de début et de fin non autorisées).')
            return
        }

        const fetchFeuillesDeRoute = async () => {
            const feuillesDeRouteRef = collection(db, 'feuillesDeRoute')
            let q = query(feuillesDeRouteRef, where('userId', '==', selectedUser.userId))
    
            // Si une période est sélectionnée 
            if (startDate && endDate) {
                const startTimestamp = Timestamp.fromDate(new Date(startDate))
                const endDateObj = new Date(endDate)
                endDateObj.setHours(23, 59, 59, 999)
                const endTimestamp = Timestamp.fromDate(endDateObj)
                q = query(q, where('date', '>=', startTimestamp), where('date', '<=', endTimestamp))
            }  
            // Ou un mois est sélectionné 
            else if (selectedMonthYear) {
                const [year, month] = selectedMonthYear.split('-').map(Number);
                const startOfMonthDate = startOfMonth(new Date(year, month - 1));
                const endOfMonthDate = endOfMonth(new Date(year, month - 1));
                const startTimestamp = Timestamp.fromDate(startOfMonthDate);
                const endTimestamp = Timestamp.fromDate(endOfMonthDate);
                q = query(q, where('date', '>=', startTimestamp), where('date', '<=', endTimestamp));
            }
    
            // Récupère les feuilles de route qui correspondent
            try {
                const querySnapshot = await getDocs(q)

                if (!querySnapshot.empty) {
                    const feuilles = querySnapshot.docs.map(doc => doc.data())

                    setFeuillesDeRoute(feuilles)
                    calculateVisites(feuilles)
                } 
                else {
                    setFeuillesDeRoute([])
                    setNombreVisites(0)
                    setNombreVisitesClient(0)
                    setNombreVisitesProspect(0)
                    setShowResults(false)
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des feuilles de route : ", error)
            }
        }
        
        const fetchDemonstrationCount = async () => {
            try {
                if (!selectedUser) {
                    return
                }
        
                const salonsRef = collection(db, 'salons')
                const salonsSnapshot = await getDocs(salonsRef)
                
                let totalDemonstrations = 0
                let startTimestamp, endTimestamp, selectedYear, selectedMonth
        
                if (startDate && endDate) {
                    startTimestamp = new Date(startDate).getTime()
                    endTimestamp = new Date(endDate).getTime()
                } 
                else if (selectedMonthYear) {
                    [selectedYear, selectedMonth] = selectedMonthYear.split('-').map(Number)
                }
        
                salonsSnapshot.forEach(doc => {
                    const salonData = doc.data();
                    if (salonData.crDemonstration) {
                        salonData.crDemonstration.forEach(demo => {
                            if (demo.userId === selectedUser.userId && demo.createdAt && demo.createdAt.seconds) {
                                const demoDate = demo.createdAt.seconds * 1000
                                if ((startDate && endDate && demoDate >= startTimestamp && demoDate <= endTimestamp) ||
                                    (selectedMonthYear && selectedYear && selectedMonth && new Date(demoDate).getFullYear() === selectedYear && new Date(demoDate).getMonth() === selectedMonth - 1)) {
                                    totalDemonstrations++
                                }
                            }
                        })
                    }
                })
        
                setNombreFichesDemonstration(totalDemonstrations)
            } 
            catch (error) {
                console.error("Erreur lors de la récupération des fiches de démonstration : ", error)
            }
        }

        fetchFeuillesDeRoute()
        fetchDemonstrationCount()
        setShowResults(true)
        setError("")
    }

    const handleExport = () => {
        const feuillesData = feuillesDeRoute.flatMap((feuille) => {
            const stops = Array.isArray(feuille?.stops) ? feuille?.stops : []

            if (stops?.length === 0) {
                return [{
                    'Date': formatTimestamp(feuille.date),
                    'Visite N°': feuille.motif || 'Motif non disponible',
                    'Nom du salon': '',
                    'Statut': '',
                    'Adresse': '',
                    'Code postal': '',
                    'Km parcourus': '',
                    'Heure d\'arrivée': '',
                    'Heure de départ': '',
                    'Total Distance': '',
                    'Total Visites': '',
                    'Visites Client': '',
                    'Visites Prospect': '',
                    'Validé le': ''
                }, {}]
            }

            const stopsData = stops?.map((stop, idx) => ({
                'Date': formatTimestamp(feuille.date),
                'Visite N°': idx < feuille?.stops.length - 1 ? `Visite n°${idx + 1}` : 'Retour',
                'Nom du salon': stop?.name || '',
                'Statut': stop?.status || '',
                'Adresse': stop?.address || '',
                'Code postal': `${stop?.postalCode || ''}`,
                'Km parcourus': formatDistance(stop?.distance || 0),
                'Heure d\'arrivée': stop?.arrivalTime || '',
                'Heure de départ': stop?.departureTime || '',
                'Total Distance': idx === feuille.stops?.length - 1 ? formatDistance(feuille.totalKm || 0) : '',
                'Total Visites': idx === feuille.stops?.length - 1 ? getNombreDeVisites(feuille.stops) : '',
                'Visites Client': idx === feuille.stops?.length - 1 ? countVisitesByStatus(feuille.stops, 'Client') : '',
                'Visites Prospect': idx === feuille.stops?.length - 1 ? countVisitesByStatus(feuille.stops, 'Prospect') : '',
                'Validé le': idx === feuille.stops?.length - 1 ? (feuille.signatureDate || 'Non disponible') : ''
            }))

            // Saut de ligne après chaque feuille pour une meilleure lecture du tableau 
            return [...stopsData, {}]
        })

        const selectedPeriod = startDate && endDate
            ? `Du ${formatDate(startDate)} au ${formatDate(endDate)}`
            : selectedMonthYear
                ? new Date(selectedMonthYear.split('-')[0], selectedMonthYear.split('-')[1] - 1).toLocaleString('default', { month: 'long', year: 'numeric' })
                : ''

        const recapData = [
            ['Nom du VRP', searchTerm],
            ['Période sélectionnée', selectedPeriod],
            ['Nombre de visites', nombreVisites],
            ['Visites client', nombreVisitesClient],
            ['Visites prospect', nombreVisitesProspect],
            ['CR de RDV de démonstration', nombreFichesDemonstration],
        ]

        exportToExcel([].concat(feuillesData, recapData), `FDR_${searchTerm}_${selectedPeriod}.xlsx`, ['Feuilles de route', 'Récapitulatif'], [feuillesData, recapData]);
    }

  

    return (
        <div  className='filter-feuilles filter-feuilles-admin' style={{marginTop: "30px", padding: "0 20px"}}>
            <div  className='filters filters-admin'>
                <div className='filters-input-admin'>
                    <div className='input-admin-mini'>
                        <div style={{width: "30%", marginRight: "30px"}} >
                            <label  className='label'>Rechercher un VRP</label><br></br>
                            <input style={{marginBottom: "10px"}} type="text" placeholder="Prénom Nom" value={searchTerm} onChange={handleUserSearch} />

                            <ul className='select-sugg'>
                                {filteredUsers.map(user => (
                                    <li style={{padding: "5px", borderBottom: "#cfcfcf solid 1px", cursor: "pointer"}} key={user.userId} onClick={() => handleUserSelection(user)}>
                                        {user.firstname} {user.lastname}
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div  style={{width: "30%", marginRight: "30px"}}>
                            <label  className='label'>Date de début </label><br></br>
                            <input className='custom-select' type="date" value={startDate} onChange={handleStartDateChange} />
                        </div>
                        <div  style={{width: "30%", marginRight: "30px"}}>
                            <label className='label'>Date de fin </label><br></br>
                            <input className='custom-select' type="date" value={endDate} onChange={handleEndDateChange} />
                        </div>
                    </div>

                    <div className='input-admin-mini'> 
                        <div  style={{width: "30%", marginRight: "30px"}}>
                            <label className='label'>Sélectionner un mois</label><br></br>
                            {/*<input className='custom-select' style={{padding: "5px 10px"}} type="month" value={selectedMonthYear} onChange={handleMonthChange} />*/}
                            {isFirefox() ? (
                                <input className="custom-select"  style={{ padding: "5px 10px" }} type="text"  value={selectedMonthYear} placeholder="décembre 2024" onChange={handleMonthChange}  />
                            ) : (
                                <input className="custom-select" style={{ padding: "5px 10px" }} type="month" value={selectedMonthYear} onChange={handleMonthChange}/>
                            )}
                        </div>

                        <div style={{width: "20%", marginRight: "0px"}}>
                            <input type="radio" id="daily" name="formatt" value="daily" checked={formatt === 'daily'} onChange={handleFormatChange} className='checkbox' />
                            <label>Feuilles journalières</label>
                        </div><br></br>
                        <div style={{width: "20%", marginRight: "0px"}}>
                            <input type="radio" className='checkbox' id="monthly" name="formatt" value="monthly" checked={formatt === 'monthly'} onChange={handleFormatChange} />
                            <label>Feuilles mensuelles</label>
                        </div>
                        <button  onClick={handleSubmit} className='button-colored mini'>Valider</button>
                    </div>

                    <button className='reset-form' onClick={resetForm}>Réinitialiser les filtres</button>

                    {error && (<p style={{color: "red", marginTop: "20px"}}>{error}</p>)}
                </div>
            </div>

            <SearchFeuillesHebdo /> 

            {showResults && (
                <button style={{display: "flex", alignItems: "center", padding: "5px 20px" , marginTop: "30px"}} className="download-f button-colored" onClick={handleExport}><img style={{marginRight: "10px"}} src={download} alt="Télécharger les feuilles de route" />Télécharger les feuilles de route</button>
            )}

            <div className='search-test' style={{width: "100%"}}>
                {showResults && ( 
                    <div  className='filter-feuilles-stats-admin' style={{marginBottom: "20px"}}> 
                        <div className='part1'>
                            <p><strong>Période sélectionnée</strong> : {startDate && endDate ? `Du ${formatDate(startDate)} au ${formatDate(endDate)}` : selectedMonthYear ? new Date(selectedMonthYear.split('-')[0], selectedMonthYear.split('-')[1] - 1).toLocaleString('default', { month: 'long', year: 'numeric' }) : ''}</p>
                            <p><strong>Nom du VRP</strong> : {searchTerm}</p>
                        </div> 
                        <div className='part2'>
                            <p><strong>Nombre de visites</strong><span>{nombreVisites}</span></p>
                            <p><strong>Visites client</strong><span>{nombreVisitesClient}</span></p>
                            <p><strong>Visites prospect</strong><span>{nombreVisitesProspect}</span></p>
                            <p><strong>Cr de RDV de démonstration</strong><span>{nombreFichesDemonstration}</span></p>
                        </div>
                    </div>
                )}
 
                {/** AFFICHE LES FEUILLES JOURNALIERES */}
                {showResults && formatt === 'daily' && showDaily && feuillesDeRoute.length > 0 && (
                    <div className="content"> 
                    {feuillesDeRoute.map((feuille, index) => (
                        <div key={index} className='feuille-du-jour  feuille-this-filter'>
                            <h3 style={{textAlign: "center", marginBottom: "20px"}}>Feuille du {feuille.date ? formatTimestamp(feuille?.date) : "Date non disponible"}</h3>

                            {feuille.isVisitsStarted && (
                                <>
                                    {feuille.stops && feuille.stops.map((stop, idx) => (
                                        <>
                                        {idx < feuille.stops.length - 1 ? (
                                            <p style={{ background: "white", display: "inline-block", padding: "5px", marginTop: "20px" }}><strong>Visite n°{idx + 1}</strong></p>
                                        ) : (
                                            <p style={{ background: "white", display: "inline-block", padding: "5px", marginTop: "20px" }}><strong>Retour</strong></p>
                                        )}
                                        <div className='visites' key={idx}>
                                            {stop.name && (
                                                <div>
                                                    <p className='titre'>Nom</p>
                                                    <p className='texte'>{stop.name}</p>
                                                </div>
                                            )}
                                            {stop.status && (
                                                <div>
                                                    <p className='titre'>Statut</p>
                                                    <p className='texte'>{stop.status}</p>
                                                </div>
                                            )}
                                            {stop.address && (
                                                <div>
                                                    <p className='titre'>Adresse</p>
                                                    <p className='texte'>{stop.address}</p>
                                                </div>
                                            )}
                                            {stop.postalCode && stop.city && (
                                                <div>
                                                    <p className='titre'>Ville</p>
                                                    <p className='texte'>{stop.postalCode} {stop.city}</p>
                                                </div>
                                            )}
                                            {stop.distance !== undefined && (
                                                <div>
                                                    <p className='titre'>Km parcourus</p>
                                                    <p className='texte'>{formatDistance(stop.distance)}</p>
                                                </div>
                                            )}
                                            {stop.arrivalTime && (
                                                <div>
                                                    <p className='titre'>Heure d'arrivée</p>
                                                    <p className='texte'>{stop.arrivalTime}</p>
                                                </div>
                                            )}
                                            {stop.departureTime && (
                                                <div>
                                                    <p className='titre'>Heure de départ</p>
                                                    <p className='texte'>{stop.departureTime}</p>
                                                </div>
                                            )}
                                        </div>
                                        </>
                                    ))}

                                    <div style={{background: "white", padding: "20px", marginTop: "20px", paddingTop: "10px"}}>    
                                        <p style={{ marginTop: "20px" }}><strong>Total de la distance parcourue </strong>: {formatDistance(feuille.totalKm)}</p>
                                        <p style={{ marginTop: "5px" }}><strong>Total des visites effectuées </strong>: {getNombreDeVisites(feuille.stops || [])}</p>
                                        <p style={{ marginTop: "5px" }}><strong>Visites client </strong>: {countVisitesByStatus(feuille.stops || [], 'Client')}</p>
                                        <p style={{ marginTop: "5px" }}><strong>Visites prospect </strong>: {countVisitesByStatus(feuille.stops || [], 'Prospect')}</p>
                                        <p style={{ marginTop: "20px" }}>Validé le <strong>{feuille?.signatureDate}</strong></p> 
                                    </div>
                                </>
                            )}

                            
                            {feuille.isVisitsStarted === false && (  
                                <div style={{background: "white", padding: "20px", paddingTop: "20px"}}>
                                    <p style={{marginBottom: "10px"}}><strong>Aucun déplacement effectué</strong></p>
                                    <p><strong>Motif</strong> : {feuille.motif}</p> 
                                </div>
                            )} 
                        </div>
                    ))} 
                    </div>
                )}

                {/** AFFICHE LES FEUILLES MENSUELLES */}
                {showResults && formatt === 'monthly' && showMonthly && feuillesDeRoute.length > 0 && ( 
                    <FeuillesMensuellesAdmin feuillesDeRoute={feuillesDeRoute} selectedMonthYear={selectedMonthYear} selectedUser={selectedUser} />
                )}
            </div>
        </div>
    )
}

export default SearchFeuillesDuJour